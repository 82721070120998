import React from "react"
import {FormattedMessage, Link, useIntl} from "gatsby-plugin-intl"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import {Row, Col, Card, Divider, Button, Alert} from "antd";
import logo from "../../assets/images/sp/sp-logo-6.svg"
import {DownloadOutlined, LinkOutlined} from "@ant-design/icons";
import consulca1 from "../../assets/images/sp/6/consulca-1.jpg";
import consulca2 from "../../assets/images/sp/6/consulca-2.jpg";

const SponsorPage = () => {
    const intl = useIntl()
    return (
        <Layout>
            <SEO
                lang={intl.locale}
                title={`Consulca SA | Sponsor | ${intl.formatMessage({ id: "title" })}`}
                keywords={["wuerth, wuerth open, open lugano, golf, wuerth golf open, golf lugano"]}
            />
            <div className="page-sponsor-detail">
                <div className="container text-center pt-4">
                    <h6 className="text-uppercase text-primary">Sponsor Würth Open 2021</h6>
                    <h4 className="text-uppercase fw-bold">Buca 6</h4>
                    <img src={logo} alt="Logo" className="sp-logo"/><br/>
                    <a href="https://www.consulca.ch/" target="_blank">
                        <Button type="primary" icon={<LinkOutlined/>} className="my-3">Sito ufficiale</Button>
                    </a>
                </div>
                <section className="bg-secondary">
                    <div className="container">
                        <Row gutter={{xs: 16, lg: 48}} align="top">
                            <Col xs={{span: 24}} className="text-justify">
                                <h6 className="text-primary text-uppercase fw-bold">Consulca SA</h6>
                                <h1>Attività e servizi</h1>
                                <h3>Gestione immobiliare e tecnica</h3>
                                <p>Prestazioni amministrative e contabili, consulenza tecnica per stabili locativi e
                                    condominiali.
                                    Nello specifico ci occupiamo di:</p>
                                <ul>
                                    <li> Gestione e tenuta della contabilità di stabili e condomini, verifica incassi
                                        pigioni, controllo e pagamenti fatture, allestimento conguagli riscaldamento e
                                        spese accessorie, corrispondenza e contatto diretto con inquilini e proprietari
                                    </li>
                                    <li>Procedure di incasso quote condominiali, organizzazione e conduzione di
                                        assemblee
                                    </li>
                                    <li>Sopraluoghi, assistenza tecnica, verifica e supervisione lavori di
                                        manutenzione
                                    </li>
                                    <li>Allestimento bilanci e analisi di redditività degli stabili</li>
                                    <li>Prima locazione di unità abitative, quali ville, case singole, appartamenti e
                                        spazi commerciali
                                    </li>
                                </ul>
                                <Divider/>
                                <h3>Compra-vendita di immobili</h3>
                                <ul>
                                    <li>Valutazioni, mediazione e commercializzazione immobiliare</li>
                                    <li>Perizie</li>
                                    <li>Assistenza per finanziamenti acquirente, assistenza nel trapasso immobiliare
                                    </li>
                                </ul>
                                <Divider/>
                                <h3>Consulenza amministrativa, contabile e giuridica</h3>
                                <ul>
                                    <li>Assistenza, consulenza, tenuta e organizzazione della contabilità (registrazioni, fatturazione, elaborazione stipendi, conteggi oneri sociali, corrispondenza,…)</li>
                                    <li>Allestimento di bilanci</li>
                                    <li>Consulenza nell’ambito della gestione del personale</li>
                                    <li>Consulenza per pratiche di liquidazione, fusioni e mutazioni di società</li>
                                    <li>Costituzioni, amministrazione e domiciliazione di società con sede in Svizzera</li>
                                    <li>Trasformazioni di società a nome individuale in società anonime o altre</li>
                                    <li>Consulenza nell’allestimento di contratti e regolamenti</li>
                                    <li>Trasferimenti di sede in stretta collaborazione con uno studio legale</li>
                                </ul>
                                <Divider/>
                                <h3>Revisioni contabili</h3>
                                <ul>
                                    <li>Esecuzione di revisioni limitate</li>
                                    <li>Esecuzione di revisioni di fondazioni, associazioni, consorzi, società di persone</li>
                                </ul>
                                <Divider/>
                                <h3>Consulenza fiscale</h3>
                                <ul>
                                    <li>Consulenza, allestimento e controllo dichiarazioni fiscali per persone fisiche e giuridiche</li>
                                    <li>Rappresentanza nei confronti delle autorità fiscali (per esempio nell’ambito di verifiche fiscali o reclami di tassazioni)</li>
                                    <li>Allestimento e controllo dichiarazioni imposte di successione</li>
                                    <li>Allestimento e controllo tassazioni utili immobiliari</li>
                                    <li>Assistenza in materia di imposte cantonali e federali, tasse di successione e donazione, imposta preventiva</li>
                                    <li>Consulenza e assistenza nell’ambito dell’IVA</li>
                                    <li>Consulenza e pianificazione fiscale nell’ambito della costituzione di nuove società, della vendita di aziende, di fusioni e di trasformazioni</li>
                                    <li>Consulenza e pianificazione fiscale nell’ambito di nuovi investimenti finanziari e immobiliari</li>
                                </ul>
                            </Col>
                        </Row>
                    </div>
                </section>
                <section>
                    <div className="container">
                        <Row gutter={{xs: 16, lg: 48}} align="top">
                            <Col xs={{span: 24, order: 1}} lg={{span: 12, order: 1}} className="text-justify">
                                <Row gutter={{xs: 16, lg: 32}}>
                                    <Col xs={24} lg={10}>
                                        <img src={consulca1} alt="Consulca"
                                             className="sp-img d-inline-block align-top mb-4 rounded shadow"/>
                                    </Col>
                                    <Col xs={24} lg={14}>
                                        <h6 className="text-primary text-uppercase fw-bold">Sede di</h6>
                                        <h3>Bellinzona</h3>
                                        <p>Indirizzo: Piazza Collegiata 1,<br/>6500 Bellinzona <br/>
                                            E-mail: info@consulca.ch <br/>
                                            Telefono: +41 091 8211262 <br/>
                                            Fax: +41 91 821 12 69</p>
                                        <a href="https://www.consulca.ch/consulca-bellinzona" target="_blank">
                                            <Button type="primary" className="mb-4">Visita il sito</Button>
                                        </a>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={{span: 24, order: 2}} lg={{span: 12, order: 2}} className="text-justify">
                                <Row gutter={{xs: 16, lg: 32}}>
                                    <Col xs={24} lg={10}>
                                        <img src={consulca2} alt="Consulca"
                                             className="sp-img d-inline-block align-top mb-4 rounded shadow"/>
                                    </Col>
                                    <Col xs={24} lg={14}>
                                        <h6 className="text-primary text-uppercase fw-bold">Sede di</h6>
                                        <h3>Savosa - Lugano</h3>
                                        <p>Indirizzo: via Tesserete 67,<br/>6942 Savosa - Lugano<br/>
                                            E-mail: lugano@consulca.ch <br/>
                                            Telefono: +41 091 961 64 64</p>
                                        <a href="https://www.consulca.ch/agenzia/savosa-lugano" target="_blank">
                                            <Button type="primary" className="mb-4">Visita il sito</Button>
                                        </a>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </section>
            </div>
        </Layout>
    )
}

export default SponsorPage
